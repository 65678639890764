var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.skin, _vm._b({
    tag: "component"
  }, 'component', _vm.$attrs, false), [_vm._l(_vm.$slots, function (_, name) {
    return _vm._t(name, null, {
      "slot": name
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
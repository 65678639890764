<template>
    <default-template>
        <v-layout justify-center align-start>
            <v-responsive width="100%" max-width="1200" class="sub-gift--list overflow-visible" :class="{ 'px-4': $vuetify.breakpoint.mobile }">
                <slot />
            </v-responsive>
        </v-layout>

        <template slot="fab" slot-scope="{ showScroll, scrollTo }">
            <slot name="fab" v-bind:showScroll="showScroll" v-bind:scrollTo="scrollTo"> </slot>
        </template>
    </default-template>
</template>
<script>
import DefaultTemplate from "../DefaultTemplate.vue";
export default {
    components: {
        DefaultTemplate,
    },
};
</script>
<style lang="scss" scoped>
.sub-gift--list {
    padding: 60px 0px;
}
@media (min-width: 768px) {
    .sub-gift--list {
        padding: 80px 0px;
    }
}
@media (min-width: 1024px) {
    .sub-gift--list {
        padding: 110px 0px;
    }
}
::v-deep {
    .headline > span > b > span {
        font-size: 0 !important;
    }
    .headline > span > b > span::after {
        content: "모바일 쿠폰";
        font-size: 24px !important;
    }
    @media (min-width: 1024px) {
        .headline > span > b > span::after {
            font-size: 28px !important;
        }
    }
}
</style>

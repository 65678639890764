<template>
    <component :is="skin" v-bind="$attrs">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    </component>
</template>
<script>
export default {
    computed: {
        skin(){
            return this.$attrs.skin ? _ => import(`@/components/client/gnb/skin/${this.$attrs.skin}/gnb.vue`) : _ => import(`@/components/client/gnb/skin/default/gnb.vue`);
        }        
    }
}
</script>
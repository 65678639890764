var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', {
    scopedSlots: _vm._u([{
      key: "fab",
      fn: function (_ref) {
        var showScroll = _ref.showScroll,
          scrollTo = _ref.scrollTo;
        return [_vm._t("fab", null, {
          "showScroll": showScroll,
          "scrollTo": scrollTo
        })];
      }
    }], null, true)
  }, [_c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-start": ""
    }
  }, [_c('v-responsive', {
    staticClass: "sub-gift--list overflow-visible",
    class: {
      'px-4': _vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "width": "100%",
      "max-width": "1200"
    }
  }, [_vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }